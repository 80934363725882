$fonts: '../assets/fonts/';

.text13 {
    font-size:rem(13px);
}
.text14 {
    font-size:rem(14px);
}
.text20 {
    font-size:rem(20px);

    @include media-max(1200px) {
        font-size:rem(18px);
    }
}
.text30 {
    font-size:rem(30px);

    @include media-max(1200px) {
        font-size:rem(26px);
    }
}
.text36 {
    font-size:rem(36px);

    @include media-max(1200px) {
        font-size:rem(30px);
    }
}
.text64 {
    font-size:rem(64px);

    @include media-max(1200px) {
        font-size:rem(42px);
    }
}
.light {
    font-weight: 300;
}
.medium {
    font-weight:600;
}
.bold {
    font-weight: 700;
}
.ls {
    letter-spacing: 3.5px;
}
.ls2 {
    letter-spacing: 2px;
}
.upper {
    text-transform: uppercase;
}
.center {
    text-align: center;
}
h1, h2, h3, h4, h5, h6 {
    margin:0;
}
@font-face {
    font-family: 'Roboto Condensed';
    src: url($fonts + 'RobotoCondensed-LightItalic.eot');
    src: local('Roboto Condensed Light Italic'), local('RobotoCondensed-LightItalic'),
        url($fonts + 'RobotoCondensed-LightItalic.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'RobotoCondensed-LightItalic.woff2') format('woff2'),
        url($fonts + 'RobotoCondensed-LightItalic.woff') format('woff'),
        url($fonts + 'RobotoCondensed-LightItalic.ttf') format('truetype'),
        url($fonts + 'RobotoCondensed-LightItalic.svg#RobotoCondensed-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url($fonts + 'RobotoCondensed-Regular.eot');
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
        url($fonts + 'RobotoCondensed-Regular.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'RobotoCondensed-Regular.woff2') format('woff2'),
        url($fonts + 'RobotoCondensed-Regular.woff') format('woff'),
        url($fonts + 'RobotoCondensed-Regular.ttf') format('truetype'),
        url($fonts + 'RobotoCondensed-Regular.svg#RobotoCondensed-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url($fonts + 'RobotoCondensed-Light.eot');
    src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'),
        url($fonts + 'RobotoCondensed-Light.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'RobotoCondensed-Light.woff2') format('woff2'),
        url($fonts + 'RobotoCondensed-Light.woff') format('woff'),
        url($fonts + 'RobotoCondensed-Light.ttf') format('truetype'),
        url($fonts + 'RobotoCondensed-Light.svg#RobotoCondensed-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url($fonts + 'RobotoCondensed-BoldItalic.eot');
    src: local('Roboto Condensed Bold Italic'), local('RobotoCondensed-BoldItalic'),
        url($fonts + 'RobotoCondensed-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'RobotoCondensed-BoldItalic.woff2') format('woff2'),
        url($fonts + 'RobotoCondensed-BoldItalic.woff') format('woff'),
        url($fonts + 'RobotoCondensed-BoldItalic.ttf') format('truetype'),
        url($fonts + 'RobotoCondensed-BoldItalic.svg#RobotoCondensed-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url($fonts + 'RobotoCondensed-Bold.eot');
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
        url($fonts + 'RobotoCondensed-Bold.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'RobotoCondensed-Bold.woff2') format('woff2'),
        url($fonts + 'RobotoCondensed-Bold.woff') format('woff'),
        url($fonts + 'RobotoCondensed-Bold.ttf') format('truetype'),
        url($fonts + 'RobotoCondensed-Bold.svg#RobotoCondensed-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url($fonts + 'RobotoCondensed-Italic.eot');
    src: local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'),
        url($fonts + 'RobotoCondensed-Italic.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'RobotoCondensed-Italic.woff2') format('woff2'),
        url($fonts + 'RobotoCondensed-Italic.woff') format('woff'),
        url($fonts + 'RobotoCondensed-Italic.ttf') format('truetype'),
        url($fonts + 'RobotoCondensed-Italic.svg#RobotoCondensed-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url($fonts + 'Oswald-Light.eot');
    src: local('Oswald Light'), local('Oswald-Light'),
        url($fonts + 'Oswald-Light.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'Oswald-Light.woff2') format('woff2'),
        url($fonts + 'Oswald-Light.woff') format('woff'),
        url($fonts + 'Oswald-Light.ttf') format('truetype'),
        url($fonts + 'Oswald-Light.svg#Oswald-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url($fonts + 'Oswald-Medium.eot');
    src: local('Oswald Medium'), local('Oswald-Medium'),
        url($fonts + 'Oswald-Medium.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'Oswald-Medium.woff2') format('woff2'),
        url($fonts + 'Oswald-Medium.woff') format('woff'),
        url($fonts + 'Oswald-Medium.ttf') format('truetype'),
        url($fonts + 'Oswald-Medium.svg#Oswald-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url($fonts + 'Oswald-SemiBold.eot');
    src: local('Oswald SemiBold'), local('Oswald-SemiBold'),
        url($fonts + 'Oswald-SemiBold.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'Oswald-SemiBold.woff2') format('woff2'),
        url($fonts + 'Oswald-SemiBold.woff') format('woff'),
        url($fonts + 'Oswald-SemiBold.ttf') format('truetype'),
        url($fonts + 'Oswald-SemiBold.svg#Oswald-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url($fonts + 'Oswald-Regular.eot');
    src: local('Oswald Regular'), local('Oswald-Regular'),
        url($fonts + 'Oswald-Regular.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'Oswald-Regular.woff2') format('woff2'),
        url($fonts + 'Oswald-Regular.woff') format('woff'),
        url($fonts + 'Oswald-Regular.ttf') format('truetype'),
        url($fonts + 'Oswald-Regular.svg#Oswald-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url($fonts + 'Oswald-Bold.eot');
    src: local('Oswald Bold'), local('Oswald-Bold'),
        url($fonts + 'Oswald-Bold.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'Oswald-Bold.woff2') format('woff2'),
        url($fonts + 'Oswald-Bold.woff') format('woff'),
        url($fonts + 'Oswald-Bold.ttf') format('truetype'),
        url($fonts + 'Oswald-Bold.svg#Oswald-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url($fonts + 'Oswald-ExtraLight.eot');
    src: local('Oswald ExtraLight'), local('Oswald-ExtraLight'),
        url($fonts + 'Oswald-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'Oswald-ExtraLight.woff2') format('woff2'),
        url($fonts + 'Oswald-ExtraLight.woff') format('woff'),
        url($fonts + 'Oswald-ExtraLight.ttf') format('truetype'),
        url($fonts + 'Oswald-ExtraLight.svg#Oswald-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

.oswald {
    font-family: 'Oswald';
}