header {
    background-color:$grey1;
    position: relative;
    border-bottom:1px solid $border1;

    .uk-dropdown {
        background-color: #1d1f31;
        color: #fff;
        max-width: max-content;
        width: max-content;
        min-width: max-content;
        padding:rem(10px);

        .lang__item {
            padding:rem(8px 12px);
        }
    }
    a:hover {
        .arrow_d {
            border-color: $blue1;
        }
    }
    .arrow_d {
        display: block;
        width:5px;
        height:5px;
        border-right:1px solid $lblue;
        border-bottom:1px solid $lblue;
        transform:rotate(45deg) translate(-1px, -1px);
        transition: $t-all;
    }

    .logo__link {
        &:active, &:focus {
            color:#fff;
        }
    }

    &.uk-sticky-below {
        .logo__img {
            width: rem(100px);
            padding-top:rem(16px);
            padding-bottom:rem(16px);
        }
        .header__wrapper {
            .nav__content {
                padding-top:rem(10px);
                padding-bottom: rem(10px);
            }
        }
    }
    
    .accent {
        background-color:$blue1;
        display: block;
        width:rem(13px);
        height:100%;
        position: absolute;
        top:0;
        left:0;

        &:after {
            content:'';
            display:block;
            width:rem(13px);
            height:rem(13px);
            position: absolute;
            bottom:0;
            left:0;
            background-color: $blue2;
        }
    }

    .logo__img {
        width: rem(166px);
        padding-top:rem(26px);
        padding-bottom:rem(26px);
        transition: $t-all;
        
        @include media-max(960px) {
            width: rem(120px);
            padding-top:rem(20px);
            padding-bottom:rem(20px);
        }
    }

    .header__wrapper {
        display:flex;

        .nav__content {
            margin-left: auto;
            display: grid;
            align-content: space-between;
            justify-content: end;
            justify-items: end;
            padding-top:rem(18px);
            padding-bottom: rem(18px);
            transition: $t-all;
        }
    }
    .nav__item {
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-weight:500;
        line-height: 1;
    }

    .topbar {
        display: flex;
        gap:rem(30px);
        line-height: 1;

        a {
            display:flex;
            align-items: center;
            align-content: center;
            gap:5px;
        }
    }
    ul {
        list-style: none;
        padding:0;
        margin:0;
        display: flex;
        align-items: center;
        align-content: center;
        gap:rem(30px);
    }
}
.menu__toggle {
    margin-left: auto;
    width:rem(40px);
    height:rem(60px);
    align-self: center;
    display: grid;
    align-items: center;
    align-content: center;
    gap:rem(10px);
    cursor: pointer;
    
    .icon {
        display:block;
        width:100%;
        height:7px;
        position: relative;
        transition: $t-all;
        
        &:before {
            transition: $t-all;
            content:'';
            position: absolute;
            bottom:100%;
            left:0;
            display: block;
            width:100%;
            height:7px;
            background-color: #fff;
        }
        &:after {
            transition: $t-all;
            content:'';
            display: block;
            position: absolute;
            top:100%;
            left:0;
            width:50%;
            height:7px;
            background-color: #fff;
        }
    }
}

.uk-offcanvas {
    transition: $t-all;

    .nav__item {
        font-size:rem(26px);
        font-weight: 500;
    }

    path {
        fill:#fff;
    }
    .langs {
        display: flex;

        .lang__item {
            font-weight: 500;

            a {
                border:0;
                opacity: 0.5;
            }

            &.active {
                a {
                    opacity: 1;
                }
            }
        }
    }

    .uk-offcanvas-bar {
        background: $blue2;
        padding:rem(30px);
        display: flex;
        flex-direction: column;
        align-items: stretch;
        align-content: stretch;
        width:rem(270px);
    }
    .offcanvas__contact {
        margin-top:auto;

        a {
            border:1px solid #fff;
            padding:rem(5px 10px);
            display: flex;
            width: 100%;
            margin-top: rem(20px);
            text-align: center;
            justify-content: center;
            justify-items: center;
            gap:rem(10px);
        }
    }
    
    &:before {
        transition: $t-all;
        background: rgba(40, 52, 125, 0.5);
    }

    .logo__img {
        margin-bottom:rem(40px);
    }
    ul {
        list-style: none;
        padding:0;
        margin:0;
        font-size:rem(20px);

        li {
            padding-bottom: rem(28px);

            @media (max-width:460px) and (max-height:670px) {
                padding-bottom: rem(14px);
                line-height:1.1;

                a {
                    font-size:rem(20px);
                }
            }
        }
    }
    .offcanvas__contact {
        font-size:rem(17px);
    }
}
.uk-offcanvas-container {
    .menu__toggle {
        .icon {
            display:block;
            width:100%;
            height:7px;
            position: relative;
            transform: rotate(-225deg);
    
            &:before {
                content:'';
                position: absolute;
                bottom:0%;
                left:0;
                display: block;
                width:100%;
                height:7px;
                background-color: #fff;
            }
            &:after {
                content:'';
                display: block;
                position: absolute;
                top:0%;
                left:0;
                width:100%;
                height:7px;
                background-color: #fff;
                transform: rotate(90deg);
            }
        }
    }
}