.button {
    display: flex;
    align-items: center;
    align-content: center;
    line-height: 1;
    gap:10px;
    width: max-content;
    padding:rem(13px 10px);
    
    @include media-max(640px) {
        padding:rem(18px 14px);
    }

    img,svg {
        transition: $t-all;
    }
    &.hover__left {
        padding-right:100px;

        &:hover {
            transform:translate(rem(-50px), 0);
        }
    }
    &.button__blue {
        background-color:$blue1;

        &:active, &:focus {
            color:#fff;
        }
        &:hover:focus {
            color:$blue1;
        }

        img,svg {
            color:#fff
        }
        &:hover {
            transform:translate(rem(30px), 0);
            background-color:#fff;

            img,svg {
                color:$blue1;
                transform:translate(100%, 0);
            }
        }
        &.contrast {
            
            &:hover {
                background-color: $blue2;
                color:#fff;

                img,svg {
                    color:#fff;
                }
            }
        }
    }

    &.img__button {
        &:hover {
            color:#fff;
            
            img {
                transform: translate(0, -30%);
            }
        }
    }

}
.button__container {

    &.push__left {
        margin-left:rem(-30px);

        @include media-max(640px) {
            margin-left: rem(-45px);
        }
    }

    &.fill {
        grid-column:1/-1;
    }
}

.scale__reverse {
    transform: scale(-1, 1);
}