.page__title {
    margin-top:rem(90px);
    margin-bottom:rem(90px);
}
.subpage__container {
    background-color: $greybg;
}
.subpage {
    margin-bottom:rem(120px);

    &.hero {
        margin-bottom:rem(80px);
        overflow: hidden;
        background-color: $greybg;
        
        &:after {
            display:none;
        }

        .wrapper {
            position:relative;
    
            &:after {
                content:'';
                display:block;
                width:100%;
                height:100%;
                background-color: $greybg;
                position: absolute;
                left:100%;
                top:0;
            }
        }
        @include media-max(1200px) {
            margin-bottom:rem(60px); 
        }
        @include media-max(960px) {
            margin-bottom:rem(80px);
            margin-top:rem(20px);
        }
    
        .pattern {
            width:100%;
            height:100%;
            position: absolute;
            top:0;
            left:-15px;
            z-index: -1;
    
            @for $i from 1 through 1000 {
                .bar:nth-child(#{$i}){
                  position: absolute;
                  top:0;
                  left: $i * 15px;
                };
            }
        }
        h1 {
            margin-bottom: rem(40px);

            @include media-max(960px) {
                font-size: rem(36px);
            }
        }
    
        .hero__grid {
            display:grid;
            grid-template-columns: repeat(2, minmax(0,1fr));
            align-items: start;
            
            @include media-min(1441px) {
                grid-template-columns: repeat(2, minmax(0,1fr));
            }
            
            @include media-max(1200px) {
                grid-template-columns: repeat(2, minmax(0,1fr));
            }
            @include media-max(960px) {
                grid-template-columns: 1fr;
                
                .primary {
                    order:1;
                }
                .secondary {
                    order:2;
                }
            }
        }
        .hero__card {
            position: relative;
            margin-top:rem(100px);
            padding-bottom:rem(95px);
            margin-bottom: 0;

            &:after {
                content:'';
                display:block;
                width:rem(96px);
                height:rem(96px);
                border-top:rem(48px) solid transparent;
                border-left:rem(48px) solid transparent;
                border-bottom:rem(48px) solid $greybg;
                border-right:rem(48px) solid $greybg;
                position: absolute;
                right:0;
                bottom:0;
            }
            
            @include media-min(1441px) {
                margin-bottom:rem(0px);
            }
    
            @include media-max(1200px) {
                margin-bottom: rem(0px);
            }
    
            @include media-max(960px) {
                margin-bottom: 0;
                margin-left:rem(40px);
                margin-top:rem(0);
    
                &:after {
                    content:'';
                    display:block;
                    width:100%;
                    height:100%;
                    position: absolute;
                    left:100%;
                    top:0;
                    background-color: $darkbg;
                }
            }
            @include media-max(460px) {
                margin-left:0;
            }
        }
        .hero__photo {
            position: relative;
            isolation: isolate;

            &:after {
                content:'';
                display:block;
                width:100%;
                height:200%;
                background-color: $greybg;
                position: absolute;
                top:0;
                z-index: -1;

                @include media-max(960px) {
                    display: none;
                }
            }
    
            @include media-max(960px) {
                margin-left:-6%;
            }
            
            img {
                width:100%;
    
                @include media-max(960px) {
                    width:100%;
                    height:94vw;
                    object-fit: cover;
                }
            }
    
            .accent {
                background-color:$blue1;
                display: block;
                height:rem(13px);
                width:100%;
                position: absolute;
                top:0;
                left:0;
        
                &:after {
                    content:'';
                    display:block;
                    width:rem(13px);
                    height:rem(13px);
                    position: absolute;
                    bottom:0;
                    right:0;
                    background-color: $blue2;
                }
    
                &.secondary {
                    @include media-max(960px) {
                        height:rem(13px);
                        width:100%;
                        position: absolute;
                        top:auto;
                        bottom:0;
                        left:0;
                
                        &:after {
                            content:'';
                            display:block;
                            width:rem(13px);
                            height:rem(13px);
                            position: absolute;
                            bottom:0;
                            right:auto;
                            left:0;
                            background-color: $blue2;
                        }
                    }
                }
            }
        }
        .inner__padding {
            padding:rem(0 110px);

            @include media-max(640px) {
                padding:rem(0 50px);
            }
        }
    }
}

.sub__content {
    p {
        margin-bottom: 35px;
    }
    h1, h2, h3, h4, h5, h6 {
        position: relative;
        padding-left: rem(60px);
        font-weight: bold;
        margin-bottom: 35px;

        &:before {
            content: '';
            display: block;
            width:rem(13px);
            height:100%;
            background-color: $blue1;
            position: absolute;
            left:0;
            top:0;
            bottom:0;
        }
    }

    .lead__block {
        padding-left:rem(60px);
        position: relative;
        margin-bottom:rem(30px);
        
        .accent {
            background-color:$blue1;
            display: block;
            width:rem(13px);
            height:100%;
            position: absolute;
            top:0;
            left:0;
    
            &:after {
                content:'';
                display:block;
                width:rem(13px);
                height:rem(13px);
                position: absolute;
                top:0;
                left:0;
                background-color: $blue2;
            }
        }
    }
    figure {
        margin-right:rem(-75px);
        margin-left:rem(-75px);
        margin-bottom: 45px;

        figcaption {
            background-color: $darkbg;
            color:#BDC3E9;
            display: block;
            width: max-content;
            margin: auto;
            padding:rem(10px 20px);
            margin-top: rem(10px);
        }

        @include media-max(960px) {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.image__center {
    margin: auto;
    text-align:center;
}