// ------------------------------
// Variables
// ------------------------------

//Font
$font-family: 'Roboto Condensed', 'Arial', sans-serif;

//Breakpoints
$sm: 640px;
$md: 960px;
$lg: 1200px;
$xl: 1600px;
//Colors
$link: #5B70FF;
$lblue:#BDC3E9;
$blue1:#5B70FF;
$blue2:#425BFF;
$grey1:#2D2F3E;
$border1:#595C73;
$greybg:#242735;
$darkbg:#1D1F31;

.tint {
    color:$lblue;
}
.bluebg {
    background-color: $blue1;
    color:#fff;
}
.darkbg {
    background-color:$darkbg;
}
.blue {
    color:$blue1;
}
.white {
    color:#fff;
}
//Transitions
$t-color: color .3s ease-in-out;
$t-bottom: bottom .3s ease-in-out;
$t-bc: background-color .3s ease-in-out;
$t-bd-b: border-bottom .3s ease-in-out;
$t-bd-col: border-color .3s ease-in-out;
$t-left: left .3s ease-in-out;
$t-margin: margin .3s ease-in-out;
$t-opacity: opacity .3s ease-in-out;
$t-top: top .3s ease-in-out;
$t-trans: transform .3s ease-in-out;
$t-width: width .3s ease-in-out;
$t-all: all .3s ease-in-out;
$t-all-f: all .1s ease-in-out;
$t-all-slow: all .35s ease-in-out;

