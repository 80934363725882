.wrapper {
  max-width: rem(1280px);
  margin-right: auto;
  margin-left: auto;

  @include media-max(1200px) {
    max-width: 90%;
  }

  &.header__wrapper {
    max-width: 90%;
  }
  &.narrow {
    max-width: 640px;

    @include media-max(960px) {
      max-width: 90%;
    }
  }
}
