.p404 {
  margin-bottom:0 !important;

  .wrapper {
    &:after {
      @include media-max(960px) {
        display: none !important;
      }
    }
  }

  @include media-max(960px) {
    margin-top: 0 !important;
    padding-top:rem(60px);
  }

  .hero__card {
    padding-bottom:rem(220px) !important;

    @include media-max(960px) {
      &:after {
        display: none !important;
      }
    }
  }
  .card__inner {
    display: flex;
    height:rem(380px);
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding-top:0;
    padding-bottom: 0;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    @include media-max(960px) {
      background-color: transparent !important;
      display: grid;
      height: auto;
      padding: 0;
      
      .p404main {
        background-color: $darkbg;
        padding: rem(30px);
        position: relative;

        &:after {
          content:'';
          display: block;
          width: 400px;
          height:100%;
          position: absolute;
          top:0;
          left:100%;
          background-color: $darkbg;
        }
      }
    }
  }
  .gif404 {
    width:rem(410px);
    height:rem(410px);
    margin-right: rem(40px);
    
    @include media-max(1200px) {
      margin-right: 0;
    }
    @include media-max(960px) {
      margin-left: auto;
      display: block;
    }
    @include media-max(460px) {
      width:rem(320px);
      height:rem(320px);
    }
  }
  .p404text {
    position: absolute;
    bottom:-6px;
    right:0;
    left:0;
    width:100%;
    max-width:rem(520px);
    z-index: -1;
    margin: auto;

    @include media-max(960px) {
      left:rem(-50px);
      right:auto;
    }
  }
}