.heading__grid {
    display: grid;
    grid-template-columns: rem(90px) auto;
    gap:rem(25px);
    
    @include media-max(1200px) {
        grid-template-columns: rem(70px) auto;
    }
    @include media-max(960px) {
        grid-template-columns: 1fr;
    }

}
.card__inner {
    padding:rem(50px 20px);
}
.flex {
    display: flex;
    gap:rem(25px);
    flex-wrap:wrap;
}
#preloader {
    max-width: 300px;
}
.preloader {
    background-color: $darkbg;
    position: fixed;
    inset:0;
    z-index:9999;
    width:100%;
    height:100vh;
    display: flex;
    place-items: center;
    place-content: center;
}
.hero {
    margin-bottom:rem(-110px);
    background-color: $greybg;
    position: relative;
    isolation: isolate;
    
    @include media-max(1200px) {
        margin-bottom:rem(-75px);
    }
    @include media-max(960px) {
        margin-bottom:0;

        h1 {
            font-size: rem(120px);
        }
    }

    .card__inner {
        @include media-max(640px) {
            padding: rem(25px 25px 38px);
        } 
    }
    .pattern {
        width:100%;
        height:100%;
        position: absolute;
        top:0;
        left:-15px;
        z-index: -1;

        @for $i from 1 through 1000 {
            .bar:nth-child(#{$i}){
              position: absolute;
              top:0;
              left: $i * 15px;
            };
        }
    }
    h1 {
        margin-bottom: rem(40px);

        @include media-max(640px) {
            font-size: rem(70px);
            margin-top: rem(20px);
        }
    }

    .hero__grid {
        display:grid;
        grid-template-columns: auto rem(680px);
        align-items: end;
        
        @include media-min(1441px) {
            grid-template-columns: auto rem(622px);
        }

        @include media-max(1200px) {
            grid-template-columns: auto rem(500px);
        }
        @include media-max(960px) {
            grid-template-columns: 1fr;
        }
    }
    .hero__card {
        margin-bottom:rem(220px);
        position: relative;
        
        @include media-min(1441px) {
            margin-bottom:rem(280px);
        }

        @include media-max(1200px) {
            margin-bottom: rem(150px);
        }

        @include media-max(960px) {
            margin-bottom: 0;
            margin-left:rem(70px);

            &:after {
                content:'';
                display:block;
                width:100%;
                height:100%;
                position: absolute;
                left:100%;
                top:0;
                background-color: $darkbg;
            }
        }
        @include media-max(640px) {
            margin-right:-6%;

            &:after {
                display: none;
            }
        }
    }
    .hero__photo {
        position: relative;

        @include media-max(960px) {
            margin-left:-6%;
        }
        @include media-max(640px) {
            margin-top: rem(-13px);
            margin-left: auto;
            margin-right: -6%;
            width:75%;
        }
        
        img {
            width:100%;

            @include media-max(960px) {
                width:100%;
                height:94vw;
                object-fit: cover;
            }
        }

        .accent {
            background-color:$blue1;
            display: block;
            height:rem(13px);
            width:100%;
            position: absolute;
            top:0;
            left:0;
            z-index:9999;
    
            &:after {
                content:'';
                display:block;
                width:rem(13px);
                height:rem(13px);
                position: absolute;
                bottom:0;
                right:0;
                background-color: $blue2;
            }

            &.secondary {
                @include media-max(960px) {
                    height:rem(13px);
                    width:100%;
                    position: absolute;
                    top:auto;
                    bottom:0;
                    left:0;
            
                    &:after {
                        content:'';
                        display:block;
                        width:rem(13px);
                        height:rem(13px);
                        position: absolute;
                        bottom:0;
                        right:auto;
                        left:0;
                        background-color: $blue2;
                    }
                }
            }
        }
    }
}
.about {
    position: relative;
    z-index: 9;
    margin-bottom: rem(80px);

    .ip__sign {
        width:100%;
        max-width: 100%;
    }

    .heading__grid {
        padding-top:rem(90px);
        padding-bottom:rem(40px);
        padding-right:rem(100px);
        
        @include media-max(960px) {
            padding-right:rem(40px);
            padding-top:rem(50px);
            padding-bottom:rem(30px);
        }
    }

    .section__inner {
        margin-right: rem(110px);
        display:grid;
        grid-template-columns:auto rem(300px);
        position: relative;
        
        @include media-max(960px) {
            grid-template-columns: 1fr;
            margin-right: 0;
        }

        &:before {
            content:'';
            display: block;
            width:1000px;
            height:100%;
            background-color: $darkbg;
            position: absolute;
            right:100%;
            top:0;
            pointer-events: none;
        }
    }

    .about__content {
        position: relative;
        padding-bottom:rem(100px);
        
        @include media-max(960px) {
            padding-bottom:rem(40px);
        }
        @include media-max(640px) {
            position: relative;

            &:after {
                content:'';
                display:block;
                width:50%;
                height:100%;
                background-color: $grey1;
                position: absolute;
                left:100%;
                top:0;
                pointer-events: none;
            }
        }

        .flex {
            @include media-max(960px) {
                gap:2px;

                .button {
                    width:100%;
                }
            }
        }

        .accent {
            background-color:$blue1;
            display: block;
            height:rem(13px);
            width:100%;
            position: absolute;
            top:0;
            left:0;
    
            &:after {
                content:'';
                display:block;
                width:rem(13px);
                height:rem(13px);
                position: absolute;
                bottom:0;
                left:0;
                background-color: $blue2;
            }
        }
    }
}
.filler {
    height:rem(100px);
    background-color: $greybg;

    @include media-max(960px) {
        height:rem(30px);
    }
    @include media-max(640px) {
        height:rem(80px);
    }
}
.services {
    padding-top:rem(132px);
    position: relative;
    overflow: hidden;
    background-color: $greybg;
    isolation: isolate;

    @include media-max(960px) {
        padding-top:rem(60px);
        padding-bottom: rem(60px);
        margin-bottom: 0;
    }
    @include media-max(640px) {
        padding-top:rem(0px);

        &:after {
            content:'';
            display: block;
            width: 100%;
            height:rem(30px);
            background-color: $grey1;
            position: absolute;
            top:0;
            left:0;
            right:0;
            z-index: -1;
        }
    }

    .pattern {
        width:50%;
        position: absolute;
        left:40%;
        top:0;
        height:100%;
        z-index: -11;

        @include media-min(1201px) {
            top:rem(66px);
        }
        
        @include media-max(960px) {
            top:rem(13px);
        }
        @include media-max(640px) {
            top:rem(0px);
            left:0;

            .bar {
                width:2400px;
            }
        }
    }

    .services__grid {
        display:flex;
        display:grid;
        grid-template-columns: rem(200px) rem(266px) rem(266px) rem(266px);
        gap:rem(20px);
        margin-bottom:rem(60px);

        @include media-max(1200px) {
            grid-template-columns: rem(200px) rem(220px) rem(220px) rem(220px);
        }

        @include media-max(960px) {
            display: flex;
            flex-wrap: wrap;
            row-gap:rem(20px);
            column-gap:2%;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .card__desc {
            @include media-max(1200px) {
                font-size:rem(12px);
            }
            @include media-max(960px) {
                font-size:rem(14px);
            }
            @include media-max(640px) {
                font-size:rem(14px);
            }
        }

        &.odd {
            grid-template-columns: rem(200px) rem(410px) rem(410px);

            @include media-max(1200px) {
                grid-template-columns: rem(200px) rem(320px) rem(320px);
            }
            @include media-max(960px) {
                display: flex;
                flex-wrap: wrap;
                row-gap:rem(20px);
                column-gap:2%;
    
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .service__card {
                max-width: rem(410px);

                @include media-max(960px) {
                    flex: 1 1 32%;
                    max-width: 32%;
                }
                @include media-max(640px) {
                    flex: 1 1 100%;
                    max-width: 100%;
                }
            }

            .stretch {
                display:grid;
                gap:rem(20px);

                @include media-max(960px) {
                    display: flex;
                    flex:1 1 65%;
                }
                @include media-max(640px) {
                    display: flex;
                    flex-wrap: wrap;
                    flex:1 1 100%;
                    max-width: 100%;
                }

                .service__card {
                    min-height: 1px;

                    @include media-max(960px) {
                        width: 100%;
                        flex:1 1 100%;
                        max-width: 100%;
                    }
                    @include media-max(640px) {
                        width: 100%;
                        flex:1 1 100%;
                        max-width: 100%;
                        min-height:rem(243px);
                        max-height:rem(243px);
                    }

                    &:hover {
                        background-color: $blue1;
        
                        .card__container {
                            transform: translate(0, -75%);
                        }
        
                        .card__desc {
                            transform: translate(0,0);
                            opacity: 1;
                        }
                    }
                }

                .card__inner {
                    padding:rem(10px 25px);

                    @include media-max(1200px) {
                        padding:rem(20px 20px);
                    }
                    @include media-max(640px) {
                        padding:rem(45px);
                    }
                    
                    .card__desc {
                        padding:rem(10px 25px);
    
                        @include media-max(1200px) {
                            padding:rem(10px 20px);
                        }
                        @include media-max(640px) {
                            padding:rem(45px);
                        }
                    }
                }
            }
        }
        &.push__right {
            margin-left:rem(100px);

            @include media-max(960px) {
                margin-left: 0;
            }
        }

        .card__inner {
            padding:rem(40px 25px);

            @include media-max(1200px) {
                padding:rem(20px 20px);
            }
            @include media-max(640px) {
                padding:rem(45px);
            }
        }
        .title__card {
            max-width:rem(200px);
            width:100%;
            display: flex;
            align-items: center;
            align-content: center;
            position:relative;

            @include media-max(960px) {
                flex: 1 1 100%;
                max-width: 1000px;
            }
            @include media-max(640px) {
                padding: rem(15px);
            }

            .card__inner {
                padding:rem(13px);

                @include media-max(960px) {
                    width:100%;
                    display: flex;
                    justify-content: space-between;

                    img, svg {
                        transform: rotate(90deg);
                    }
                }
            }
        }
        .service__card {
            max-width:rem(266px);
            width:100%;
            min-height:rem(243px);
            height:100%;
            display: grid;
            border-right:1px solid $blue1;
            border-bottom:1px solid $blue1;
            background-color: $greybg;
            overflow: hidden;
            transition: $t-all;

            @include media-max(1200px) {
                max-width:rem(220px);
            }
            @include media-max(960px) {
                flex: 1 1 31.33%;
            }
            @include media-max(640px) {
                flex: 1 1 100%;
                max-width: 1000px;
                aspect-ratio: 2/1;
            }

            .card__inner {
                height:100%;
                position: relative;
            }
            .card__container {
                transition: $t-all;
                height:100%;
                display: grid;
                align-content: space-between;
                position: relative;
                gap:10px;

                & > div {
                    @include media-max(960px) {
                        position: relative;

                        &:after {
                            content:'';
                            display: block;
                            background-image: url(../assets/img/arrow-r-w.svg);
                            background-position: left center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-size: 100%;
                            width:rem(35px);
                            height:rem(25px);
                            transition: $t-all;
                            position: absolute;
                            top:120%;
                            left:0;

                            @include media-max(960px) {
                                position: static;
                            }
                        }
                    }
                }
            }
            .card__desc {
                position: absolute;
                bottom:0;
                right:0;
                left:0;
                max-width: 100%;
                transform: translate(0, 50%);
                opacity: 0;
                z-index: 99;
                padding:rem(40px 25px);
                transition: $t-all;
                height: 100%;
                display: grid;
                align-content: end;
                line-height: 1.2;

                @include media-max(1200px) {
                    padding:rem(20px 20px);
                    line-height: 1.2;
                }
                @include media-max(640px) {
                    padding:rem(45px);
                }

                p {
                    margin: 0;
                }
            }
            svg {
                color:$blue1;
            }

            &:hover {
                background-color: $blue1;

                .card__container {
                    transform: translate(0, -90%);
                    
                    @include media-max(1200px) {
                        transform: translate(0, -80%) !important;
                    }
                    @include media-max(960px) {
                        transform: translate(0, -70%) !important;
                    }
                    @include media-max(640px) {
                        transform: translate(0, -70%) !important;
                    }

                    & > div {
                        &:after {
                            opacity:0;
                        }
                    }
                }

                .card__desc {
                    transform: translate(0,0);
                    opacity: 1;
                }
            }
        }
    }
}
.skills {
    background-color: $greybg;
    
    h3, .desc {
        margin-bottom:rem(40px);
    }

    .accent {
        position: absolute;
        width:rem(425px);
        height:rem(13px);
        top:0;
        left:0;
        background: $greybg;

        @include media-max(640px) {
            width:50%;
        }

        &:after {
            content:'';
            display: block;
            width: rem(13px);
            height:rem(13px);
            background-color: $blue2;
            position: absolute;
            top:0;
            right:0;
        }
    }
    .skills__content {
        padding:rem(55px 30px);
        position: relative;
        z-index: 99;
        isolation: isolate;
        
        @include media-max(960px) {
            padding-bottom:rem(140px);
        }
        &:after {
            content: '';
            display: block;
            width:100%;
            height:100%;
            background-color: $blue1;
            position: absolute;
            left:100%;
            top:0;
            pointer-events: none;
        }
    }
    .skills__grid {
        display: grid;
        grid-template-columns: auto rem(525px);
        gap:rem(40px);
        margin-left: rem(105px);

        @include media-max(1200px) {
            grid-template-columns: repeat(2, minmax(0,1fr));
            margin-left: rem(80px);
        }

        @include media-max(960px) {
            margin-left: 0;
            margin-top: rem(30px);
            grid-template-columns: 1fr;
        }
    }
    .tags__container {
        @include media-max(960px) {
            order:3;
        }
    }
    .taglist {
        row-gap:rem(15px);
        column-gap:rem(20px);
        padding-top:rem(60px);

        @include media-max(960px) {
            padding-top: 0;
        }
    }
    .tag {
        border:2px solid $lblue;
        padding:rem(2px 9px);
        background-color: $blue1;
        
        @include media-max(960px) {
            padding:rem(1px 5px);
        }

        .tint {
            font-weight: 200;
        }
    }
    .images {
        pointer-events: none;
        position: absolute;
        bottom:0;
        left:0;
        display: flex;
        gap:rem(26px);
        z-index: -1;
        
        @include media-max(960px) {
            gap:rem(10px);
        }
        img {
            width:rem(34px);
            
            @include media-max(960px) {
                width:rem(24px);
            }
        }
        img:first-child {
            margin-right:rem(22px);
            
            @include media-max(960px) {
                margin-right:rem(12px);
            }
        }
    }
}
.cases {
    margin-bottom: rem(150px);
    
    @include media-min(961px) {
        margin-top: rem(150px);
    }
    @include media-max(960px) {
        margin-bottom: rem(110px);
    }

    .case__card {
        position:relative;

        img {
            width:100%;
        }
        @include media-min(960px) {
            &:after {
                position: absolute;
                right:-3%;
                bottom:-3%;
                content:'';
                display:block;
                width:0;
                height:0;
                border-right: 1px solid $blue1;
                border-bottom: 1px solid $blue1;
                transition: $t-all;
                pointer-events: none;
                opacity:0;
            }
            &:before {
                position: absolute;
                left:-3%;
                top:-3%;
                content:'';
                display:block;
                width:0;
                height:0;
                border-left: 1px solid $blue1;
                border-top: 1px solid $blue1;
                transition: $t-all;
                pointer-events: none;
                opacity:0;
            }
            &:hover {
                &:before, &:after {
                    width:106%;
                    height:106%;
                    opacity:1;
                }
            }
        }

        a {
            color:inherit;
        }
        .case__image {
            position: relative;
            @include media-max(960px) {
                &:after {
                    content:'';
                    display: block;
                    background-image: url(../assets/img/arrow-r-w.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 70%;
                    width:rem(45px);
                    height:rem(45px);
                    background-color: $blue1;
                    position: absolute;
                    z-index: 9;
                    bottom:0;
                    left:0;
                    border-right:7px solid $greybg;
                    border-top:7px solid $greybg;
                }
            }
            @include media-max(640px) {
                &:after {
                    width:rem(70px);
                    height:rem(70px);
                }
            }
        }
        .card__title {
            margin-top:rem(15px);
            margin-bottom:rem(15px);
        }
    }
}
.uk__arrows {

    a[uk-slider-item="previous"], a[uk-slider-item="next"] {
        background-color:$blue1;
        width:rem(60px);
        height:rem(60px);
        display: flex;
        place-content: center;
        place-items: center;
        margin: 0;
        left:101%;

        @include media-max(1200px) {
            width:rem(45px);
            height:rem(45px);
        }
        &:hover, &:focus, &:active {
            color:#fff;
        }
    }
    a[uk-slider-item="previous"] {
        left:auto;
        right:101%;
        img, svg {
            transform: scale(-1, 1);
        }
    }
    img, svg {
        width:30px !important;
        height:20px !important;
        max-width: 30px;
        max-height: 20px;
    }
}
.testimonials {
    position: relative;
    background-color: $greybg;
    padding-top: rem(150px);
    
    @include media-max(960px) {
        padding-top: rem(100px);
    }
    
    .pattern__bg {
        position: relative;

        .pattern__bg_image {
            position: absolute;
            top:50%;
            left:0;
            right:0;
            transform: translate(0, -50%);
            width: 100%;
            height:300px;
            background-position: center;
            background-repeat: repeat-x;
            background-size: cover;

            img {
                height:100%;
                max-width:unset;
                object-fit: cover;
            }
        }

        @include media-max(960px) {
            padding-right: rem(50px);
            padding-left: rem(50px);
        }
    }
    .title__row {
        display: flex;
        gap:rem(30px);
        margin-bottom:rem(45px);
        
        h3 {
            position: relative;
            top:rem(-5px);
            
            @include media-max(960px) {
                top:rem(-6px);
            }
        }
    }

    .uk-slider-nav {
        &.uk-dotnav {
            align-items: flex-end;
            min-height:50px;
            position: absolute;
            width: 100%;
            right:0;
            left:0;

            li {
                a {
                    width:7px;
                    height:10px;
                    background:#BDC3E9;
                    display: block;
                    opacity:0.5;
                }

                &.uk-active {
                    a {
                        height:15px;
                        opacity:1;
                    }
                }
            }
        }
    }

    .uk-slider-items {
        li {
            display:flex;
            place-content: center;
            place-items: center;
        }
    }
    .testim__body {
        background-color: $darkbg;
        position: relative;

        &:after {
            content:'';
            display: block;
            width:rem(30px);
            height:rem(30px);
            background-color:$blue1;
            position: absolute;
            right:0;
            bottom:0;
            border-top:rem(15px) solid $darkbg;
            border-left:rem(15px) solid $darkbg;
            border-right:rem(15px) solid $blue1;
            border-bottom:rem(15px) solid $blue1;
        }

        .testim__inner {
            padding:rem(40px 25px 20px);

            .content {
                margin-bottom:rem(20px);
            }
            .testim__author {
                display:flex;
                gap:rem(20px);
                align-items: center;
                align-content: center;
                position: relative;
                padding-top:rem(25px);
                line-height: 1.3;
                color:#BDC3E9;

                @include media-max(960px) {
                    display: grid;
                    grid-template-columns: rem(92px) auto;
                    align-content: center;
                    align-items: center;
                    grid-template-rows: auto auto;
                    grid-template-areas:
                        'photo link'
                        'author author';
                }

                &:before {
                    content:'';
                    display:block;
                    width:rem(30px);
                    height:rem(10px);
                    background-color: $blue1;
                    position: absolute;
                    top:0;
                    transform: translate(0, -50%);
                }

                .author__image {
                    border:2px solid #595C73;
                    border-radius: 1000px;
                    overflow:hidden;
                    min-width: rem(92px);
                    height:rem(92px);
                    
                    @include media-max(1200px) {
                        min-width: rem(86px);
                        height:rem(86px);
                    }

                    @include media-max(960px) {
                        grid-area: photo;
                    }

                    img {
                        min-width:100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .author__info {
                    border-right:1px solid #BDC3E9;
                    padding-right:rem(20px);

                    @include media-max(960px) {
                        border:0;
                        grid-area: 'author';
                        grid-column: 1/-1;
                    }
                }
            }
            .author__socials {
                height:100%;
                display: flex;
                align-self: center;
                gap:rem(13px);
                flex-wrap: wrap;
                align-items: center;
                align-content: center;

                @include media-max(960px) {
                    padding-left: rem(20px);
                    grid-area:link;
                    border-left:1px solid #BDC3E9;
                }
            }
        }
    }
}
.pattern {
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:-25px;
    z-index: -1;

    @for $i from 1 through 1000 {
        .bar:nth-child(#{$i}){
          position: absolute;
          top:0;
          left: $i * 25px;
        };
    }

    .bar {
        width:1000px;

        .bar__body {
            width:100%;
            height:1px;
            background-color: #3F4B9A;
            display: block;
            transform-origin: left center;
            transform: rotate(135deg);

        }
    }
}