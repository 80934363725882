footer {
    background-color: $greybg;
    padding-top: rem(150px);

    @include media-max(960px) {
        padding-top: rem(100px);
    }

    .grid__1_1_1 {
        @include media-max(960px) {
            grid-template-columns: repeat(2, minmax(0,1fr));
        }
        @include media-max(960px) {
            grid-template-columns: 1fr;
        }
    }
    .copy {
        margin-top:rem(40px);
    }
    .footer__card {
        border-right:1px solid $blue1;
        border-bottom:1px solid $blue1;
        padding:rem(30px);
        display: grid;
        transition: $t-all;

        * {
            transition: $t-all;
        }

        &:hover {
            background-color: $blue1;
            color:#fff;
            
            * {
                color:#fff;
            }
        }
        
        a {
            display: grid;
            align-content: space-between;
            height: 100%;
        }

        img, svg {
            margin-bottom:rem(25px);
        }
        .item__title {
            margin-bottom:rem(20px);
        }
        .phone {
            width:rem(26px);
        }
        .env {
            width:rem(46px);
        }
    }
    .contact__card {
        display: grid;
        align-items: center;

        @include media-max(960px) {
            grid-column: 1/-1;

            * {
                fill: #fff;
            }
        }
        p {
            margin: 0;
        }

        img, svg {
            margin-bottom: rem(25px);
        }

        .inner__card {
            display: grid;
            align-content: space-between;
        }
    }
}
