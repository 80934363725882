.grid {
    display: grid;
}
.grid__1_1_1 {
    grid-template-columns: repeat(3, minmax(0,1fr));
    gap:rem(35px);

    @include media-max(640px) {
        grid-template-columns: 1fr;
    }
}
.grid__1_1_1_1 {
    grid-template-columns: repeat(4, minmax(0,1fr));
    gap:rem(35px);

    @include media-max(960px) {
        grid-template-columns: repeat(2, minmax(0,1fr));
    }
    @include media-max(640px) {
        grid-template-columns: 1fr;
    }
}
.row__gap {
    row-gap:rem(70px);
}